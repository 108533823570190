export const rounds2 = [
	{
		id: 11,
		time: '09:00',
		tables: [
			{ nr: 1, match: { show: true, event: 'P13', players: [1, 7] } },
			{ nr: 2, match: { show: true, event: 'P13', players: [2, 8] } },
			{ nr: 3, match: { show: true, event: 'P13', players: [3, 10] } },
			{ nr: 4, match: { show: true, event: 'P13', players: [4, 6] } },
			{ nr: 5, match: { show: true, event: 'P13', players: [5, 9] } },
			{ nr: 6, match: { show: true, event: 'F13', players: [1, 7] } },
			{ nr: 7, match: { show: true, event: 'F13', players: [2, 8] } },
			{ nr: 8, match: { show: true, event: 'F15', players: [1, 7] } },
			{ nr: 9, match: { show: true, event: 'F15', players: [2, 8] } },
		],
	},
	{
		id: 12,
		time: '09:25',
		tables: [
			{ nr: 1, match: { show: true, event: 'F13', players: [3, 10] } },
			{ nr: 2, match: { show: true, event: 'F13', players: [4, 6] } },
			{ nr: 3, match: { show: true, event: 'F13', players: [5, 9] } },
			{ nr: 4, match: { show: true, event: 'F15', players: [3, 10] } },
			{ nr: 5, match: { show: true, event: 'F15', players: [4, 6] } },
			{ nr: 6, match: { show: true, event: 'F15', players: [5, 9] } },
			{ nr: 7, match: { show: true, event: 'P15', players: [1, 7] } },
			{ nr: 8, match: { show: true, event: 'P15', players: [2, 8] } },
			{ nr: 9, match: { show: true, event: 'P15', players: [3, 10] } },
		],
	},
	{
		id: 13,
		time: '09:50',
		tables: [
			{ nr: 1, match: { show: true, event: 'P15', players: [4, 6] } },
			{ nr: 2, match: { show: true, event: 'P15', players: [5, 9] } },
			{ nr: 3, match: { show: true, event: 'P17', players: [1, 7] } },
			{ nr: 4, match: { show: true, event: 'P17', players: [2, 8] } },
			{ nr: 5, match: { show: true, event: 'P17', players: [3, 10] } },
			{ nr: 6, match: { show: true, event: 'P17', players: [4, 6] } },
			{ nr: 7, match: { show: true, event: 'P17', players: [5, 9] } },
			{ nr: 8, match: { show: true, event: 'F17', players: [1, 7] } },
			{ nr: 9, match: { show: true, event: 'F17', players: [2, 8] } },
		],
	},
	{
		id: 14,
		time: '10:20',
		tables: [
			{ nr: 1, match: { show: true, event: 'F17', players: [3, 10] } },
			{ nr: 2, match: { show: true, event: 'F17', players: [4, 6] } },
			{ nr: 3, match: { show: true, event: 'F17', players: [5, 9] } },
			{ nr: 4, match: { show: true, event: 'P13', players: [1, 6] } },
			{ nr: 5, match: { show: true, event: 'P13', players: [2, 5] } },
			{ nr: 6, match: { show: true, event: 'P13', players: [3, 9] } },
			{ nr: 7, match: { show: true, event: 'F13', players: [1, 6] } },
			{ nr: 8, match: { show: true, event: 'F13', players: [2, 5] } },
			{ nr: 9, match: { show: true, event: 'F13', players: [3, 9] } },
		],
	},
	{
		id: 15,
		time: '10:45',
		tables: [
			{ nr: 1, match: { show: true, event: 'P13', players: [4, 8] } },
			{ nr: 2, match: { show: true, event: 'P13', players: [7, 10] } },
			{ nr: 3, match: { show: true, event: 'F13', players: [4, 8] } },
			{ nr: 4, match: { show: true, event: 'F13', players: [7, 10] } },
			{ nr: 5, match: { show: true, event: 'F15', players: [1, 6] } },
			{ nr: 6, match: { show: true, event: 'F15', players: [2, 5] } },
			{ nr: 7, match: { show: true, event: 'F15', players: [3, 9] } },
			{ nr: 8, match: { show: true, event: 'F15', players: [4, 8] } },
			{ nr: 9, match: { show: true, event: 'F15', players: [7, 10] } },
		],
	},
	{
		id: 16,
		time: '11:10',
		tables: [
			{ nr: 1, match: { show: true, event: 'P15', players: [1, 6] } },
			{ nr: 2, match: { show: true, event: 'P15', players: [2, 5] } },
			{ nr: 3, match: { show: true, event: 'P15', players: [3, 9] } },
			{ nr: 4, match: { show: true, event: 'P15', players: [4, 8] } },
			{ nr: 5, match: { show: true, event: 'P15', players: [7, 10] } },
			{ nr: 6, match: { show: true, event: 'P17', players: [1, 6] } },
			{ nr: 7, match: { show: true, event: 'P17', players: [2, 5] } },
			{ nr: 8, match: { show: true, event: 'F17', players: [1, 6] } },
			{ nr: 9, match: { show: true, event: 'F17', players: [2, 5] } },
		],
	},
	{
		id: 17,
		time: '11:40',
		tables: [
			{ nr: 1, match: { show: true, event: 'P17', players: [3, 9] } },
			{ nr: 2, match: { show: true, event: 'P17', players: [4, 8] } },
			{ nr: 3, match: { show: true, event: 'P17', players: [7, 10] } },
			{ nr: 4, match: { show: true, event: 'F17', players: [3, 9] } },
			{ nr: 5, match: { show: true, event: 'F17', players: [4, 8] } },
			{ nr: 6, match: { show: true, event: 'F17', players: [7, 10] } },
			{ nr: 7, match: { show: true, event: 'P13', players: [1, 5] } },
			{ nr: 8, match: { show: true, event: 'P13', players: [2, 6] } },
			{ nr: 9, match: { show: true, event: 'P13', players: [3, 7] } },
		],
	},
	{
		id: 18,
		time: '12:10',
		tables: [
			{ nr: 1, match: { show: true, event: 'P13', players: [4, 10] } },
			{ nr: 2, match: { show: true, event: 'P13', players: [8, 9] } },
			{ nr: 3, match: { show: true, event: 'F13', players: [1, 5] } },
			{ nr: 4, match: { show: true, event: 'F13', players: [2, 6] } },
			{ nr: 5, match: { show: true, event: 'F13', players: [3, 7] } },
			{ nr: 6, match: { show: true, event: 'F13', players: [4, 10] } },
			{ nr: 7, match: { show: true, event: 'F13', players: [8, 9] } },
			{ nr: 8, match: { show: true, event: 'F15', players: [1, 5] } },
			{ nr: 9, match: { show: true, event: 'F15', players: [2, 6] } },
		],
	},
	{
		id: 19,
		time: '12:35',
		tables: [
			{ nr: 1, match: { show: true, event: 'F15', players: [3, 7] } },
			{ nr: 2, match: { show: true, event: 'F15', players: [4, 10] } },
			{ nr: 3, match: { show: true, event: 'F15', players: [8, 9] } },
			{ nr: 4, match: { show: true, event: 'P15', players: [1, 5] } },
			{ nr: 5, match: { show: true, event: 'P15', players: [2, 6] } },
			{ nr: 6, match: { show: true, event: 'P15', players: [3, 7] } },
			{ nr: 7, match: { show: true, event: 'P17', players: [1, 5] } },
			{ nr: 8, match: { show: true, event: 'P17', players: [2, 6] } },
			{ nr: 9, match: { show: true, event: 'P17', players: [3, 7] } },
		],
	},
	{
		id: 20,
		time: '13:00',
		tables: [
			{ nr: 1, match: { show: true, event: 'P15', players: [4, 10] } },
			{ nr: 2, match: { show: true, event: 'P15', players: [8, 9] } },
			{ nr: 3, match: { show: true, event: 'P17', players: [4, 10] } },
			{ nr: 4, match: { show: true, event: 'P17', players: [8, 9] } },
			{ nr: 5, match: { show: true, event: 'F17', players: [1, 5] } },
			{ nr: 6, match: { show: true, event: 'F17', players: [2, 6] } },
			{ nr: 7, match: { show: true, event: 'F17', players: [3, 7] } },
			{ nr: 8, match: { show: true, event: 'F17', players: [4, 10] } },
			{ nr: 9, match: { show: true, event: 'F17', players: [8, 9] } },
		],
	},
	{ id: 21, time: 'LUNCH', tables: [] },
	{
		id: 22,
		time: '14:30',
		tables: [
			{ nr: 1, match: { show: true, event: 'P13', players: [1, 4] } },
			{ nr: 2, match: { show: true, event: 'P13', players: [2, 3] } },
			{ nr: 3, match: { show: true, event: 'P13', players: [5, 8] } },
			{ nr: 4, match: { show: true, event: 'P13', players: [6, 10] } },
			{ nr: 5, match: { show: true, event: 'P13', players: [7, 9] } },
			{ nr: 6, match: { show: true, event: 'F13', players: [1, 4] } },
			{ nr: 7, match: { show: true, event: 'F13', players: [2, 3] } },
			{ nr: 8, match: { show: true, event: 'F15', players: [1, 4] } },
			{ nr: 9, match: { show: true, event: 'F15', players: [2, 3] } },
		],
	},
	{
		id: 23,
		time: '14:55',
		tables: [
			{ nr: 1, match: { show: true, event: 'F13', players: [5, 8] } },
			{ nr: 2, match: { show: true, event: 'F13', players: [6, 10] } },
			{ nr: 3, match: { show: true, event: 'F13', players: [7, 9] } },
			{ nr: 4, match: { show: true, event: 'F15', players: [5, 8] } },
			{ nr: 5, match: { show: true, event: 'F15', players: [6, 10] } },
			{ nr: 6, match: { show: true, event: 'F15', players: [7, 9] } },
			{ nr: 7, match: { show: true, event: 'P15', players: [1, 4] } },
			{ nr: 8, match: { show: true, event: 'P15', players: [2, 3] } },
			{ nr: 9, match: { show: true, event: 'P15', players: [5, 8] } },
		],
	},
	{
		id: 24,
		time: '15:20',
		tables: [
			{ nr: 1, match: { show: true, event: 'P15', players: [6, 10] } },
			{ nr: 2, match: { show: true, event: 'P15', players: [7, 9] } },
			{ nr: 3, match: { show: true, event: 'P17', players: [1, 4] } },
			{ nr: 4, match: { show: true, event: 'P17', players: [2, 3] } },
			{ nr: 5, match: { show: true, event: 'P17', players: [5, 8] } },
			{ nr: 6, match: { show: true, event: 'P17', players: [6, 10] } },
			{ nr: 7, match: { show: true, event: 'P17', players: [7, 9] } },
			{ nr: 8, match: { show: true, event: 'F17', players: [1, 4] } },
			{ nr: 9, match: { show: true, event: 'F17', players: [2, 3] } },
		],
	},
	{
		id: 25,
		time: '15:50',
		tables: [
			{ nr: 1, match: { show: true, event: 'F17', players: [5, 8] } },
			{ nr: 2, match: { show: true, event: 'F17', players: [6, 10] } },
			{ nr: 3, match: { show: true, event: 'F17', players: [7, 9] } },
			{ nr: 4, match: { show: true, event: 'P13', players: [1, 3] } },
			{ nr: 5, match: { show: true, event: 'P13', players: [2, 4] } },
			{ nr: 6, match: { show: true, event: 'P13', players: [5, 7] } },
			{ nr: 7, match: { show: true, event: 'F13', players: [1, 3] } },
			{ nr: 8, match: { show: true, event: 'F13', players: [2, 4] } },
			{ nr: 9, match: { show: true, event: 'F13', players: [5, 7] } },
		],
	},
	{
		id: 26,
		time: '16:15',
		tables: [
			{ nr: 1, match: { show: true, event: 'P13', players: [6, 9] } },
			{ nr: 2, match: { show: true, event: 'P13', players: [8, 10] } },
			{ nr: 3, match: { show: true, event: 'F13', players: [6, 9] } },
			{ nr: 4, match: { show: true, event: 'F13', players: [8, 10] } },
			{ nr: 5, match: { show: true, event: 'F15', players: [1, 3] } },
			{ nr: 6, match: { show: true, event: 'F15', players: [2, 4] } },
			{ nr: 7, match: { show: true, event: 'F15', players: [5, 7] } },
			{ nr: 8, match: { show: true, event: 'F15', players: [6, 9] } },
			{ nr: 9, match: { show: true, event: 'F17', players: [7, 8] } },
		],
	},
	{
		id: 27,
		time: '16:40',
		tables: [
			{ nr: 1, match: { show: true, event: 'P15', players: [1, 3] } },
			{ nr: 2, match: { show: true, event: 'P15', players: [2, 4] } },
			{ nr: 3, match: { show: true, event: 'P15', players: [5, 7] } },
			{ nr: 4, match: { show: true, event: 'P15', players: [6, 9] } },
			{ nr: 5, match: { show: true, event: 'P15', players: [8, 10] } },
			{ nr: 6, match: { show: true, event: 'P17', players: [1, 3] } },
			{ nr: 7, match: { show: true, event: 'P17', players: [2, 4] } },
			{ nr: 8, match: { show: true, event: 'F17', players: [1, 3] } },
			{ nr: 9, match: { show: true, event: 'F17', players: [2, 4] } },
		],
	},
	{
		id: 28,
		time: '17:10',
		tables: [
			{ nr: 1, match: { show: true, event: 'P17', players: [5, 7] } },
			{ nr: 2, match: { show: true, event: 'P17', players: [6, 9] } },
			{ nr: 3, match: { show: true, event: 'P17', players: [8, 10] } },
			{ nr: 4, match: { show: true, event: 'F17', players: [5, 7] } },
			{ nr: 5, match: { show: true, event: 'F17', players: [6, 9] } },
			{ nr: 6, match: { show: true, event: 'F17', players: [8, 10] } },
			{ nr: 7, match: { show: true, event: 'P13', players: [1, 2] } },
			{ nr: 8, match: { show: true, event: 'P13', players: [3, 4] } },
			{ nr: 9, match: { show: true, event: 'P13', players: [5, 6] } },
		],
	},
	{
		id: 29,
		time: '17:40',
		tables: [
			{ nr: 1, match: { show: true, event: 'P13', players: [7, 8] } },
			{ nr: 2, match: { show: true, event: 'P13', players: [9, 10] } },
			{ nr: 3, match: { show: true, event: 'F13', players: [1, 2] } },
			{ nr: 4, match: { show: true, event: 'F13', players: [3, 4] } },
			{ nr: 5, match: { show: true, event: 'F13', players: [5, 6] } },
			{ nr: 6, match: { show: true, event: 'F13', players: [7, 8] } },
			{ nr: 7, match: { show: true, event: 'F13', players: [9, 10] } },
			{ nr: 8, match: { show: true, event: 'F15', players: [1, 2] } },
			{ nr: 9, match: { show: true, event: 'F15', players: [3, 4] } },
		],
	},
	{
		id: 30,
		time: '18:05',
		tables: [
			{ nr: 1, match: { show: true, event: 'F15', players: [5, 6] } },
			{ nr: 2, match: { show: true, event: 'F15', players: [7, 8] } },
			{ nr: 3, match: { show: true, event: 'F15', players: [9, 10] } },
			{ nr: 4, match: { show: true, event: 'P15', players: [1, 2] } },
			{ nr: 5, match: { show: true, event: 'P15', players: [3, 4] } },
			{ nr: 6, match: { show: true, event: 'P15', players: [5, 6] } },
			{ nr: 7, match: { show: true, event: 'P17', players: [1, 2] } },
			{ nr: 8, match: { show: true, event: 'P17', players: [3, 4] } },
			{ nr: 9, match: { show: true, event: 'P17', players: [5, 6] } },
		],
	},
	{
		id: 31,
		time: '18:30',
		tables: [
			{ nr: 1, match: { show: true, event: 'P15', players: [7, 8] } },
			{ nr: 2, match: { show: true, event: 'P15', players: [9, 10] } },
			{ nr: 3, match: { show: true, event: 'P17', players: [7, 8] } },
			{ nr: 4, match: { show: true, event: 'P17', players: [9, 10] } },
			{ nr: 5, match: { show: true, event: 'F17', players: [1, 2] } },
			{ nr: 6, match: { show: true, event: 'F17', players: [3, 4] } },
			{ nr: 7, match: { show: true, event: 'F17', players: [5, 6] } },
			{ nr: 8, match: { show: true, event: 'F17', players: [10, 10] } }, // BYTE
			{ nr: 9, match: { show: true, event: 'F17', players: [9, 10] } },
		],
	},
]
