export const rounds3 = [
	{
		id: 32,
		time: '09:00',
		tables: [
			{ nr: 1, match: { show: true, event: 'P13', players: [5, 8] } },
			{ nr: 2, match: { show: true, event: 'P17', players: [4, 3] } },
			{ nr: 3, match: { show: true, event: 'P15', players: [1, 6] } },
			{ nr: 4, match: { show: true, event: 'P15', players: [4, 5] } },
			{ nr: 5, match: { show: true, event: 'P17', players: [2, 8] } },
			{ nr: 6, match: { show: true, event: 'P13', players: [3, 4] } }
		]
	},
	{
		id: 33,
		time: '09:50',
		tables: [
			{ nr: 1, match: { show: true, event: 'F13', players: [3, 6] } },
			{ nr: 2, match: { show: true, event: 'F13', players: [2, 4] } },
			{ nr: 3, match: { show: true, event: 'F15', players: [4, 3] } },
			{ nr: 4, match: { show: true, event: 'F15', players: [5, 7] } },
			{ nr: 5, match: { show: true, event: 'F17', players: [6, 7] } },
			{ nr: 6, match: { show: true, event: 'F17', players: [5, 3] } }
		]
	},
	{
		id: 34,
		time: '10:40',
		tables: [
			{ nr: 1, match: { show: true, event: 'P13', players: [2, 5] } },
			{ nr: 2, match: { show: true, event: 'P13', players: [1, 4] } },
			{ nr: 3, match: { show: true, event: 'P15', players: [3, 1] } },
			{ nr: 4, match: { show: true, event: 'P15', players: [2, 4] } },
			{ nr: 5, match: { show: true, event: 'P17', players: [1, 2] } },
			{ nr: 6, match: { show: true, event: 'P17', players: [6, 3] } }
		]
	},
	{
		id: 35,
		time: '11:30',
		tables: [
			{ nr: 1, match: { show: true, event: 'F13', players: [7, 3] } },
			{ nr: 2, match: { show: true, event: 'F13', players: [1, 2] } },
			{ nr: 3, match: { show: true, event: 'F15', players: [1, 3] } },
			{ nr: 4, match: { show: true, event: 'F15', players: [2, 5] } },
			{ nr: 5, match: { show: true, event: 'F17', players: [2, 6] } },
			{ nr: 6, match: { show: true, event: 'F17', players: [1, 3] } }
		]
	},
	{ id: 36, time: 'LUNCH', tables: [] },
	{
		id: 35,
		time: '13:00',
		tables: [
			{ nr: 1, match: { show: true, event: 'P13 pl.5-6', players: [3, 8] } },
			{ nr: 2, match: { show: true, event: 'P15', players: [5, 6] } },
			{ nr: 3, match: { show: true, event: 'P17', players: [4, 8] } },
			{ nr: 4, match: { show: true, event: 'F13', players: [4, 6] } },
			{ nr: 5, match: { show: true, event: 'F15', players: [4, 7] } },
			{ nr: 6, match: { show: true, event: 'F17', players: [5, 7] } }
		]
	},
	{
		id: 36,
		time: '13:30',
		tables: [
			{ nr: 1, match: { show: true, event: 'P13', players: [1, 5] } },
			{ nr: 2, match: { show: true, event: 'P15', players: [3, 4] } },
			{ nr: 3, match: { show: true, event: 'P17', players: [6, 1] } },
			{ nr: 4, match: { show: true, event: 'F13', players: [7, 2] } },
			{ nr: 5, match: { show: true, event: 'F15', players: [5, 3] } },
			{ nr: 6, match: { show: true, event: 'F17', players: [6, 3] } }
		]
	},
	{
		id: 37,
		time: '14:00',
		tables: [
			{ nr: 1, match: { show: true, event: 'P13', players: [2, 4] } },
			{ nr: 2, match: { show: true, event: 'P15', players: [2, 1] } },
			{ nr: 3, match: { show: true, event: 'P17', players: [2, 3] } },
			{ nr: 4, match: { show: true, event: 'F13', players: [1, 3] } },
			{ nr: 5, match: { show: true, event: 'F15', players: [2, 1] } },
			{ nr: 6, match: { show: true, event: 'F17', players: [1, 2] } }
		]
	}
]
