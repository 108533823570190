export const events = [
	{
		event: 'P13',
		id: 'P13',
		players: [
			{ id: 'P13_1', name: 'Madhu Chandra, Bharath' }, 	// 1 
			{ id: 'P13_2', name: 'Ståhl, Olle' }, 				// 2
			{ id: 'P13_3', name: 'Jobs, Hugo' },				// 4
			{ id: 'P13_4', name: 'Wallin Grawsiöö, Adam' },		// 5
			{ id: 'P13_5', name: 'Hedblad, Sigge' },			// 3
			{ id: 'P13_6', name: 'Enbom, Noah' },				
			{ id: 'P13_7', name: 'VAKANT' },
			{ id: 'P13_8', name: 'Alvendal, Theo' },			// 6
			{ id: 'P13_9', name: 'Olsson, William' },
			{ id: 'P13_10', name: 'Hermann, Magne' },
		]
	},
	{
		event: 'F13',
		id: 'F13',
		players: [
			{ id: 'F13_1', name: 'Benjegård, Siri' },		// 1
			{ id: 'F13_2', name: 'Aaltonen, Gabriella' },	// 4
			{ id: 'F13_3', name: 'Ellermann, Clara' },		// 3
			{ id: 'F13_4', name: 'Bebawy, Angelina' },		// 5
			{ id: 'F13_5', name: 'Sahle, Sofia' },	
			{ id: 'F13_6', name: 'Gralvik, Livia' },		// 6
			{ id: 'F13_7', name: 'Engström, Betty' },		// 2
			{ id: 'F13_8', name: 'Westerlund, Olivia' },
			{ id: 'F13_9', name: 'Källström, Hilma' },	
			{ id: 'F13_10', name: 'Edvardsson, Tilda' },	
		]
	},
	{
		event: 'P15',
		id: 'P15',
		players: [
			{ id: 'P15_1', name: 'Edwardsson, Isak' },			// 3
			{ id: 'P15_2', name: 'Dahlström, Noa' },			// 1
			{ id: 'P15_3', name: 'Sjögren, Elias' },			// 2
			{ id: 'P15_4', name: 'Lidén Isaksson, Hannes' },	// 4
			{ id: 'P15_5', name: 'Gjörling, Lukas' },			// 5
			{ id: 'P15_6', name: 'Gjörling, Emil' },			// 6
			{ id: 'P15_7', name: 'Ströberg, Jesper' },
			{ id: 'P15_8', name: 'Häger, Gustav' },
			{ id: 'P15_9', name: 'Mattbäck, Kean' },
			{ id: 'P15_10', name: 'VAKANT' },
		]
	},
	{
		event: 'F15',
		id: 'F15',
		players: [
			{ id: 'F15_1', name: 'Fred, Josephina' },		// 2
			{ id: 'F15_2', name: 'Svensson, Agnes' },		// 1
			{ id: 'F15_3', name: 'Freij, Nathalie' },		// 6
			{ id: 'F15_4', name: 'Thorén, Wilma' },			// 3
			{ id: 'F15_5', name: 'Stahle Olin, Klara' },	// 4
			{ id: 'F15_6', name: 'Ferm, Indra' },
			{ id: 'F15_7', name: 'Cabardo, Laurynne' },		// 5
			{ id: 'F15_8', name: 'Svensson, Lilly' },
			{ id: 'F15_9', name: 'Forsberg, Ella' },
			{ id: 'F15_10', name: 'VAKANT' },
		]
	},
	{
		event: 'P17/20',
		id: 'P17',
		players: [
			{ id: 'P17_1', name: 'Lindsö, Daniel' },		// 2
			{ id: 'P17_2', name: 'Hedbom, Max' },			// 3
			{ id: 'P17_3', name: 'Wesshagen, Edward' },		// 5
			{ id: 'P17_4', name: 'Widing, Charlie' },		// 4
			{ id: 'P17_5', name: 'Lundquist, Elliot' },
			{ id: 'P17_6', name: 'Larsson, Dennis' },		// 1
			{ id: 'P17_7', name: 'VAKANT' },
			{ id: 'P17_8', name: 'Bramfors, Karl' },		// 6
			{ id: 'P17_9', name: 'Ridegård, Charlie' },
			{ id: 'P17_10', name: 'Skoglund, Albin' }
		]
	},
	{
		event: 'F17/20',
		id: 'F17',
		players: [
			{ id: 'F17_1', name: 'Hansson, Matilda' },		// 1
			{ id: 'F17_2', name: 'Johansson, Linnea' },		// 2
			{ id: 'F17_3', name: 'Natanaelsson, Moa' },		// 5
			{ id: 'F17_4', name: 'Öberg, Tilde' },
			{ id: 'F17_5', name: 'Fuhrman, Zara' },			// 4
			{ id: 'F17_6', name: 'Taus, Amanda' },			// 3
			{ id: 'F17_7', name: 'Raftheim, Malin' },		// 6
			{ id: 'F17_8', name: 'Wålme, Malva' },
			{ id: 'F17_9', name: 'VAKANT' },
			{ id: 'F17_10', name: 'VAKANT' },
		]
	}
]
