
import { defineComponent } from 'vue'
import Popper from 'vue3-popper'
export default defineComponent({
	name: 'RoundRow',
	props: ['round', 'events'],
	components: { Popper },
	methods: {
		popperText({ event, players }: { event: string; players: number[] }, nr: number) {
			if (!players.length) {
				return 'TBA - TBA'
			}
			const matchEvent = this.events.find(({ id }) => event.includes(id))

			const player1 = matchEvent.players.find(p => p.id === `${event}_${players[0]}`)
			const player2 = matchEvent.players.find(p => p.id === `${event}_${players[1]}`)
			const name1 = (player1 && player1.name )|| 'TBA'
			const name2 = (player2 && player2.name )|| 'TBA'
			return `${this.round.time}: Bord ${nr}: ${name1} - ${name2}`
		},
		cellText({ players, event }: { event: string; players: number[] }) {
			const firstPlayer = players[0] || 'TBA'
			const secondPlayer = players[1] || 'TBA'
			return players.length ? `${firstPlayer} - ${secondPlayer}` : event
		},
		getClass({ show, event, players }: { show: boolean, event: string; players: number[] }) {
			const matchEvent = this.events.find(({ id }) => id === event)
			// slutspelsmatch
			if (!matchEvent) return show && event

			const eventPlayers = matchEvent.players.filter(p =>
				players.map(pp => `${event}_${pp}`).some(pp => pp === p.id)
			) as any[]

			const isVakant = eventPlayers.some(p => p.name === 'VAKANT')
			if (isVakant && show) {
				return 'VAKANT'
			}

			return show && event
		}
	}
})
