import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7f961444"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "border" }
const _hoisted_2 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Popper = _resolveComponent("Popper")!

  return (_openBlock(), _createElementBlock("tr", _hoisted_1, [
    _createElementVNode("td", null, _toDisplayString(_ctx.round.time), 1),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.round.tables, (t) => {
      return (_openBlock(), _createElementBlock("td", {
        key: t.nr,
        class: _normalizeClass(["border border-gray-900 bg-gray-500", _ctx.getClass(t.match)])
      }, [
        _createVNode(_component_Popper, {
          content: _ctx.popperText(t.match, t.nr)
        }, {
          default: _withCtx(() => [
            (t.match.show)
              ? (_openBlock(), _createElementBlock("p", _hoisted_2, _toDisplayString(_ctx.cellText(t.match)), 1))
              : _createCommentVNode("", true)
          ]),
          _: 2
        }, 1032, ["content"])
      ], 2))
    }), 128))
  ]))
}