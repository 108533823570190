
import { defineComponent } from 'vue'
import EventSelect from '@/components/EventSelect.vue'
import RoundRow from '@/components/RoundRow.vue'
import { rounds1 } from '@/assets/rounds1'
import { rounds2 } from '@/assets/rounds2'
import { rounds3 } from '@/assets/rounds3'
import { events } from '@/assets/events'

export default defineComponent({
	name: 'Home',
	components: { EventSelect, RoundRow },
	data() {
		return {
			rounds1,
			rounds2,
			rounds3,
			events
		}
	},
	methods: {
		main(playerId: string) {
			if (!playerId) {
				this.rounds1.forEach(r => r.tables.forEach(t => (t.match.show = true)))
				this.rounds2.forEach(r => r.tables.forEach(t => (t.match.show = true)))
				this.rounds3.forEach(r => r.tables.forEach(t => (t.match.show = true)))
			} else {
				const a = playerId.split('_')
				this.rounds1.forEach(r => {
					r.tables.forEach(t => {
						t.match.show = t.match.event === a[0] && t.match.players.includes(+a[1])
					})
				})
				this.rounds2.forEach(r => {
					r.tables.forEach(t => {
						t.match.show = t.match.event === a[0] && t.match.players.includes(+a[1])
					})
				})
				this.rounds3.forEach(r => {
					r.tables.forEach(t => {
						t.match.show =
							t.match.event.includes(a[0]) && (!t.match.players.length || t.match.players.includes(+a[1]))
					})
				})
			}
		}
	}
})
