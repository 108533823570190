
import { defineComponent } from 'vue'
import { events } from '@/assets/events'
export default defineComponent({
	name: 'EventSelect',
	data() {
		return {
			events,
			playerId: ''
		}
	},
	methods: {
		onChange() {
			const event = this.events.find((e) => e.players.some((p) => p.id === this.playerId))
			const player = event?.players.find((p) => p.id === this.playerId)
			this.$emit('player-selected', player?.id)
		}
	}
})
