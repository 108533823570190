export const rounds1 = [
	{
		id: 1,
		time: '15:30',
		tables: [
			{ nr: 1, match: { show: true, event: 'P13', players: [1, 10] } },
			{ nr: 2, match: { show: true, event: 'P13', players: [2, 9] } },
			{ nr: 3, match: { show: true, event: 'P13', players: [3, 5] } },
			{ nr: 4, match: { show: true, event: 'P13', players: [4, 7] } },
			{ nr: 5, match: { show: true, event: 'P13', players: [6, 8] } },
			{ nr: 6, match: { show: true, event: 'F13', players: [1, 10] } },
			{ nr: 7, match: { show: true, event: 'F13', players: [2, 9] } },
			{ nr: 8, match: { show: true, event: 'F15', players: [1, 10] } },
			{ nr: 9, match: { show: true, event: 'F15', players: [2, 9] } }
		]
	},
	{
		id: 2,
		time: '15:55',
		tables: [
			{ nr: 1, match: { show: true, event: 'F13', players: [3, 5] } },
			{ nr: 2, match: { show: true, event: 'F13', players: [4, 7] } },
			{ nr: 3, match: { show: true, event: 'F13', players: [6, 8] } },
			{ nr: 4, match: { show: true, event: 'F15', players: [3, 5] } },
			{ nr: 5, match: { show: true, event: 'F15', players: [4, 7] } },
			{ nr: 6, match: { show: true, event: 'F15', players: [6, 8] } },
			{ nr: 7, match: { show: true, event: 'P15', players: [1, 10] } },
			{ nr: 8, match: { show: true, event: 'P15', players: [2, 9] } },
			{ nr: 9, match: { show: true, event: 'P15', players: [3, 5] } }
		]
	},
	{
		id: 3,
		time: '16:20',
		tables: [
			{ nr: 1, match: { show: true, event: 'P15', players: [4, 7] } },
			{ nr: 2, match: { show: true, event: 'P15', players: [6, 8] } },
			{ nr: 3, match: { show: true, event: 'P17', players: [1, 10] } },
			{ nr: 4, match: { show: true, event: 'P17', players: [2, 9] } },
			{ nr: 5, match: { show: true, event: 'P17', players: [3, 5] } },
			{ nr: 6, match: { show: true, event: 'P17', players: [4, 7] } },
			{ nr: 7, match: { show: true, event: 'P17', players: [6, 8] } },
			{ nr: 8, match: { show: true, event: 'F17', players: [1, 10] } },
			{ nr: 9, match: { show: true, event: 'F17', players: [2, 9] } }
		]
	},
	{
		id: 4,
		time: '16:50',
		tables: [
			{ nr: 1, match: { show: true, event: 'F17', players: [3, 5] } },
			{ nr: 2, match: { show: true, event: 'F17', players: [4, 7] } },
			{ nr: 3, match: { show: true, event: 'F17', players: [6, 8] } },
			{ nr: 4, match: { show: true, event: 'P13', players: [1, 9] } },
			{ nr: 5, match: { show: true, event: 'P13', players: [2, 10] } },
			{ nr: 6, match: { show: true, event: 'P13', players: [3, 8] } },
			{ nr: 7, match: { show: true, event: 'F13', players: [1, 9] } },
			{ nr: 8, match: { show: true, event: 'F13', players: [2, 10] } },
			{ nr: 9, match: { show: true, event: 'F13', players: [3, 8] } }
		]
	},
	{
		id: 5,
		time: '17:15',
		tables: [
			{ nr: 1, match: { show: true, event: 'P13', players: [4, 5] } },
			{ nr: 2, match: { show: true, event: 'P13', players: [6, 7] } },
			{ nr: 3, match: { show: true, event: 'F13', players: [4, 5] } },
			{ nr: 4, match: { show: true, event: 'F13', players: [6, 7] } },
			{ nr: 5, match: { show: true, event: 'F15', players: [10, 10] } },
			{ nr: 6, match: { show: true, event: 'F15', players: [2, 10] } },
			{ nr: 7, match: { show: true, event: 'F15', players: [3, 8] } },
			{ nr: 8, match: { show: true, event: 'F15', players: [4, 5] } },
			{ nr: 9, match: { show: true, event: 'F15', players: [6, 7] } }
		]
	},
	{
		id: 6,
		time: '17:40',
		tables: [
			{ nr: 1, match: { show: true, event: 'P15', players: [1, 9] } },
			{ nr: 2, match: { show: true, event: 'P15', players: [2, 10] } },
			{ nr: 3, match: { show: true, event: 'P15', players: [3, 8] } },
			{ nr: 4, match: { show: true, event: 'P15', players: [4, 5] } },
			{ nr: 5, match: { show: true, event: 'P15', players: [6, 7] } },
			{ nr: 6, match: { show: true, event: 'P17', players: [1, 9] } },
			{ nr: 7, match: { show: true, event: 'P17', players: [2, 10] } },
			{ nr: 8, match: { show: true, event: 'F17', players: [1, 9] } },
			{ nr: 9, match: { show: true, event: 'F17', players: [2, 10] } }
		]
	},
	{
		id: 7,
		time: '18:10',
		tables: [
			{ nr: 1, match: { show: true, event: 'P17', players: [3, 8] } },
			{ nr: 2, match: { show: true, event: 'P17', players: [4, 5] } },
			{ nr: 3, match: { show: true, event: 'P17', players: [6, 7] } },
			{ nr: 4, match: { show: true, event: 'F17', players: [3, 8] } },
			{ nr: 5, match: { show: true, event: 'F17', players: [4, 5] } },
			{ nr: 6, match: { show: true, event: 'F17', players: [6, 7] } },
			{ nr: 7, match: { show: true, event: 'P13', players: [1, 8] } },
			{ nr: 8, match: { show: true, event: 'P13', players: [2, 7] } },
			{ nr: 9, match: { show: true, event: 'P13', players: [3, 6] } }
		]
	},
	{
		id: 8,
		time: '18:40',
		tables: [
			{ nr: 1, match: { show: true, event: 'P13', players: [4, 9] } },
			{ nr: 2, match: { show: true, event: 'P13', players: [5, 10] } },
			{ nr: 3, match: { show: true, event: 'F13', players: [1, 8] } },
			{ nr: 4, match: { show: true, event: 'F13', players: [2, 7] } },
			{ nr: 5, match: { show: true, event: 'F13', players: [3, 6] } },
			{ nr: 6, match: { show: true, event: 'F13', players: [4, 9] } },
			{ nr: 7, match: { show: true, event: 'F13', players: [5, 10] } },
			{ nr: 8, match: { show: true, event: 'F15', players: [1, 8] } },
			{ nr: 9, match: { show: true, event: 'F15', players: [2, 7] } }
		]
	},
	{
		id: 9,
		time: '19:05',
		tables: [
			{ nr: 1, match: { show: true, event: 'F15', players: [3, 6] } },
			{ nr: 2, match: { show: true, event: 'F15', players: [4, 9] } },
			{ nr: 3, match: { show: true, event: 'F15', players: [5, 10] } },
			{ nr: 4, match: { show: true, event: 'P15', players: [1, 8] } },
			{ nr: 5, match: { show: true, event: 'P15', players: [2, 7] } },
			{ nr: 6, match: { show: true, event: 'P15', players: [3, 6] } },
			{ nr: 7, match: { show: true, event: 'P17', players: [1, 8] } },
			{ nr: 8, match: { show: true, event: 'P17', players: [2, 7] } },
			{ nr: 9, match: { show: true, event: 'P17', players: [3, 6] } }
		]
	},
	{
		id: 10,
		time: '19:30',
		tables: [
			{ nr: 1, match: { show: true, event: 'P15', players: [4, 9] } },
			{ nr: 2, match: { show: true, event: 'P15', players: [5, 10] } },
			{ nr: 3, match: { show: true, event: 'P17', players: [4, 9] } },
			{ nr: 4, match: { show: true, event: 'P17', players: [5, 10] } },
			{ nr: 5, match: { show: true, event: 'F17', players: [1, 8] } },
			{ nr: 6, match: { show: true, event: 'F17', players: [2, 7] } },
			{ nr: 7, match: { show: true, event: 'F17', players: [3, 6] } },
			{ nr: 8, match: { show: true, event: 'F17', players: [4, 9] } },
			{ nr: 9, match: { show: true, event: 'F15', players: [1, 9] } }
		]
	}
]
